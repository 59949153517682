<template>
  <v-layout>
    <v-app-bar class="app-bar" :elevation="0" density="compact">
      <v-app-bar-title class="screen-title">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            class="hamburger mr-2 d-lg-none ml-0 w-auto"
            variant="text"
            style="font-size: 20px"
            @click.stop="drawer = !drawer"
          >
            |||
          </v-app-bar-nav-icon>

          <router-link to="/">
            <div class="d-flex align-center">
              <v-avatar image size="small" class="bg-gray">
                <v-img
                  :src="require('../assets/images/logo.png')"
                  alt="Avatar"
                  aspect-ratio="1"
                />
              </v-avatar>
              <div class="pl-2">
                <span class="font-weight-black">Turing</span>
                <span class="font-weight-medium text-gray-dark">Web</span>
              </div>
            </div>
          </router-link>
        </div>
      </v-app-bar-title>

      <v-spacer />
      <v-spacer class="d-none d-lg-block" />
      <v-tabs class="d-none d-lg-block ml-auto" selected-class>
        <v-tab class="pa-0 ma-0" style="min-width: 0px" />
        <!-- <v-tab class="text-gray-dark" @click="handleDivClick('channel')">
                Channels
              </v-tab> -->
        <router-link to="/FAQ">
          <v-tab
            class="text-gray-dark"
            :class="activeRoute == '/FAQ' ? 'v-tab--selected' : ''"
          >
            FAQs
          </v-tab>
        </router-link>
        <router-link to="/contact-us">
          <v-tab
            class="text-gray-dark"
            :class="activeRoute == '/contact-us' ? 'v-tab--selected' : ''"
          >
            Contact Us
          </v-tab>
        </router-link>
      </v-tabs>

      <!-- <v-btn
              size="small"
              rounded="lg"
              elevation="4"
              class="btn ml-5 px-5 bg-white light-border"
            >
              <a
                href="https://turingweb.app/app"
                target="_blank"
                rel="noopener noreferrer"
                class="text-black fs-16 try-now"
              >Try now</a>
            </v-btn>-->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="left" temporary>
      <!-- <v-list>
        <v-tab @click="handleDivClick('channel')"> Channels </v-tab>
      </v-list> -->
      <v-list>
        <router-link to="/FAQ">
          <v-tab
            class="text-gray-dark"
            :class="activeRoute == '/FAQ' ? 'v-tab--selected' : ''"
            >FAQs</v-tab
          >
        </router-link>
      </v-list>
      <v-list>
        <router-link to="/contact-us">
          <v-tab
            class="text-gray-dark"
            :class="activeRoute == '/contact-us' ? 'v-tab--selected' : ''"
            >Contact Us</v-tab
          >
        </router-link>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
  <!-- <router-view /> -->
</template>

<script>
import router from "../router";
export default {
  name: "NavComponent",
  props: {
    msg: String,
  },
  data: () => ({
    drawer: false,
    group: null,
    activeRoute: "",
    items: [
      {
        id: 1,
        title: "Channel",
        value: "/channel",
      },
      {
        id: 2,
        title: "Contact Us",
        value: "/about",
      },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.activeRoute = router.currentRoute?._value.path;
  },
  methods: {
    handleDivClick(divName) {
      router.push("/");
      this.$emit("div-clicked", divName); // Emit custom event to parent component
      console.log(router.currentRoute._value.path, "currentRoute");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #272525;
}
.hamburger {
  transform: rotate(90deg);
  :hover {
    pointer-events: none;
  }
}
.screen-title {
  margin-right: -100px;
  text-align: left;
}
// .app-bar {
//    @media (min-width: 825px) {
//     padding: 0 3em
//   };
// }
.try-now {
  margin-top: -4px;
  text-transform: none !important;
}
.v-toolbar-title__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5em !important;
}
button {
  text-transform: none !important;
}
</style>
