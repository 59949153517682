<template>
  <div class="section-3 py-24">
    <p class="font-weight-bold mb-10">
      <b>Explore Different channels</b>
    </p>
    <!-- <CarouselComponent v-for="n in 2" :key="n" /> -->
    <v-row
      v-for="n in 1"
      :key="n"
      class="my-3 overflow-x-hidden"
    >
      <v-col
        class="d-flex justify-center"
        cols="12"
        xs="12"
        sm="4"
      >
        <CarouselCard
          v-for="data in domainData"
          :name="data.name"
          :key="data.domain_id"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import CarouselComponent from "@/components/CarouselComponent.vue";
// import CarouselCard from "@/components/carouselCard.vue";
import { DOMAIN_ENDPOINT } from "@/constants/Constants";
import axios from "axios";

export default {
  name: "ChannelsView",
  components: {
    // CarouselComponent,
    // CarouselCard,
  },
  data: function () {
    return {
      sectionTwo: [
        {
          id: 1,
          title: "Frequently Asked Questions",
          text: "Explore a wide range of domains including Competitive Tests, Health and Fitness, Acute Diseases, and more. Connect with like-minded individuals facing similar challenges and discover valuable insights together",
        },
        {
          id: 2,
          title: "Frequently Asked Questions",
          text: "Quickly find answers to common queries in each domain. Our curated collection of frequently asked questions will help you get the information you need without any hassle",
        },
        {
          id: 3,
          title: "Chat with ChatGPT",
          text: "Need clarity on a specific topic or discussion? Our intelligent chatbot assistant, ChatGPT, is here to help. Engage in interactive conversations and receive instant guidance from an AI-powered expert.",
        },
      ],
      domainData: null,
      subDomainData: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get(DOMAIN_ENDPOINT)
        .then((response) => {
          console.log("axios data", response.data.response_data);
          this.domainData = response.data.response_data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 500px) {
  .home-banner {
    margin-top: 10em;
  }
}
.section-2 {
  background-color: #f5f5f5e2;
  margin: 0;
  width: 100%;
}
</style>
