<template>
  <div>
    <div class="mt-13">
      <transition
        name="fade"
        mode="out-in"
        appear
        @before-enter="scrollTop"
      >
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",

  components: {},
  methods: {
    scrollTop() {
      document.getElementById("app").scrollIntoView();
    },
  },
};
</script>

<style lang="scss">
@import "../src/styles/Main.scss";
@font-face {
  font-family: "PlusJakartaSans";
  src: url("../src/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url("../src/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: url("../src/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: url("../src/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-Italic";
  src: url("../src/assets/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
#app {
  font-family: "PlusJakartaSans", sans-serif !important;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
// body, p, a, h1, h2, div, .text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6 {
//   font-family: 'PlusJakartaSans', sans-serif !important;
// }
nav {
  a {
    font-weight: bold;
    color: #f1f2f2;

    &.router-link-exact-active {
      color: #ebf3f0;
    }
  }
}
</style>
