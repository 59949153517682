import axios from "axios";
import { API_BASE_URL } from "../constants/Constants";

// TODO: Add a createResponse method that will return {success: true/false, response: DATA, errorMsg:'' }
export default {
  createContact(contact_data) {
    return axios.post(`${API_BASE_URL}/contact/`, contact_data);
  },

  // User
  async getUserById(userId) {
    const { data } = await axios.get(`${API_BASE_URL}/user/${userId}/`);

    if (data.response_status != 200) {
      return { success: false, response: {}, errorMsg: data.description };
    }

    return {
      success: true,
      response: data?.response_data,
      errorMsg: "",
    };
  },

  createUser(dataToSend) {
    return axios.post(`${API_BASE_URL}/user/`, dataToSend);
  },

  updateUser(userId, dataToUpdate) {
    return axios.put(`${API_BASE_URL}/user/${userId}/`, dataToUpdate);
  },

  // Channels
  getAllChannelByDomainName(domainName) {
    return axios.get(`${API_BASE_URL}/domain/${domainName}/channel/`);
  },

  getAllChannels() {
    return axios.get(`${API_BASE_URL}/channel/`);
  },

  // Domains
  getAllDomains() {
    return axios.get(`${API_BASE_URL}/domain/`);
  },
};
