import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TryNow from "../views/TryNow.vue";
import Channel from "../views/ChannelsView.vue";
import FAQ from "../views/FAQ.vue";
import ContactUs from "../views/ContactUs.vue";

const TURING_WEB = "Turing Web";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: TURING_WEB },
    component: HomeView,
  },
  {
    path: "/tryNow",
    name: "tryNow",
    meta: { title: TURING_WEB },
    component: TryNow,
  },
  {
    path: "/channel",
    name: "channel",
    meta: { title: TURING_WEB },
    component: Channel,
  },
  {
    path: "/FAQ",
    name: "FAQ",
    meta: { title: TURING_WEB },
    component: FAQ,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    meta: { title: TURING_WEB },
    component: ContactUs,
  },
  {
    path: "/about",
    name: "about",
    meta: { title: TURING_WEB },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactUs.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
