<template>
  <div>
    <NavComponent />
    <!-- question section start -->
    <div id="FAQs-section">
      <v-container>
        <p class="font-weight-bold section-title">
          <b>AI Search Tips</b>
        </p>
        <p class="my-14">
          Quite simply, searching AI search works best when you enter prompts/questions like you are talking to child.
          <br />Examples below: (Pro Tip: Open this page in a new tab and copy and paste these prompts in your search)
        </p>
        <question-drop-down
          v-for="(question,index) of questionsData"
          :key="index"
          :title="question.question"
          :answer="question.answer"
          :sub-points="question.subPoints || []"
        />
      </v-container>
    </div>

    <!-- question section end -->
    <!-- <FooterComponent /> -->
  </div>
</template>

<script>
import { frequentlyAskedQuestions } from "../constants/Constants";
import QuestionDropDown from "@/components/QuestionDropDown.vue";
// import FooterComponent from "@/components/FooterComponent.vue";
import NavComponent from "@/components/NavComponent.vue";

export default {
  components: {
    QuestionDropDown,
    // FooterComponent,
    NavComponent,
  },
  data() {
    return {
      questionsData: frequentlyAskedQuestions,
    };
  },
};
</script>

<style>
</style>