<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-card
      class="my-5 px-6 rounded-lg question-card"
      v-bind="props"
      :elevation="isHovering ? 1 : 0.9"
    >
      <div
        class="flex p-2 justify-between"
        @click="toggle()"
      >
        <div class="title font-weight-medium text-gray-dark">
          {{ title }}
        </div>

        <add-icon
          v-if="!isCollapsed"
          :alt="'Open '"
          :title="'Open '"
        />
        <minus-icon
          v-else
          :alt="'Close '"
          :title="'Close '"
        />
      </div>
      <div
        v-if="isCollapsed"
        class="relative text-left h-auto"
      >
        <!-- <p class="mb-4 px-3 text-answer">{{ answer }}</p> -->
        <div
          v-for="(list, index) in answer"
          :key="index"
        >
          <p class="mb-4 px-3 text-answer">
            {{ list }}
          </p>
          <div v-if="subPoints.length">
            <div
              v-for="(subPoint, i) in subPoints"
              :key="i"
            >
              <p class="mb-4 px-3 ml-5 text-answer">
                {{ subPoint }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import AddIcon from "../icons/AddIcon.vue";
import MinusIcon from "../icons/MinusIcon.vue";
export default {
  components: {
    AddIcon,
    MinusIcon,
  },

  props: {
    title: String,
    answer: Array,
    subPoints: Array,
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggle() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.1em;
  text-align: left;
  font-family: "PlusJakartaSans-Medium";
}

.text-answer {
  font-size: 0.9em;
  border-left: 1px solid #7a7a7a7a;
  line-height: 2.5;
}

.question-card {
  box-shadow: 2px 2px 30px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

@media (max-width: 500px) {
  .title,
  .text-answer {
    font-size: 0.7em;
    letter-spacing: normal;
  }
}
</style>