<template>
  <div class="dropdown-image">
    <img
      alt="Open"
      draggable="false"
      src="@/assets/images/add.png"
      title="Open"
    >
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
.dropdown-image {
	width: 14px;
	height: 14px;
	margin: auto 0;
	display: flex;
    align-items: center;
}
</style>