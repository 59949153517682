<template>
  <footer>
    <p class="opacity text-h6">(c) Turing Web. All rights reserved</p>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}
.opacity {
  opacity: 0.7;
}
p {
  font-size: 14px !important;
}
</style>
