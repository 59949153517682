<template>
  <div class="dropdown-image">
    <img
      alt="Close"
      draggable="false"
      src="@/assets/images/minus.png"
      title="Close"
    >
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
.dropdown-image {
	width: 14px;
	height: 14px;
	margin: auto 0;
	display: flex;
    align-items: center;
}
</style>