<!-- eslint-disable vue/v-bind-style -->
<template>
  <div>
    <div v-if="loading.value" class="loader" />

    <form>
      <v-container>
        <!-- <v-text-field
          v-model="state.question"
          class="pa-xs-10"
          append-inner-icon="mdi-lightbulb-on-outline"
          :error-messages="v$.question.$errors.map((e) => e.$message)"
          label="Question"
          placeholder="Enter your question here"
          required
          variant="outlined"
          @click:append-inner="state.isPromptModalOpen = true"
          @input="v$.question.$touch"
        >
          <div
            v-if="state.question.length < 30 && !mobile"
            class="text-caption position-absolute right-0 cursor-pointer align-self-center z-index-1"
            @click="state.isPromptModalOpen = true"
          >
            Golden Prompts
          </div>
        </v-text-field> -->
        <v-row class="align-center justify-center px-0">
          <v-col class="px-0 d-sm-flex" cols="12" md="10" lg="6">
            <v-textarea
              v-model="state.question"
              auto-grow
              max-rows="4"
              class="pa-xs-10"
              label="Question"
              placeholder="Enter your question here"
              rows="1"
              row-height="15"
              required
              variant="outlined"
              append-inner-icon="mdi-lightbulb-on-outline"
              @click:append-inner="state.isPromptModalOpen = true"
              @input="v$.question.$touch"
            >
              <div
                v-if="state.question.length < 30 && !mobile"
                class="text-caption position-absolute right-0 cursor-pointer align-self-center z-index-1"
                @click="state.isPromptModalOpen = true"
              >
                Golden Prompts
              </div>
            </v-textarea>
          </v-col>
        </v-row>

        <!-- <v-row class="align-center justify-center p-0">
          <v-col class="px-0" cols="12" sm="8" md="8" lg="6">
            <v-select
              v-model="state.channel"
              clearable
              class="pt-0"
              :items="state.channelList"
              item-title="name"
              label="Select Channel or Default to General"
              return-object
              variant="outlined"
            />
          </v-col>
        </v-row> -->

        <v-col
          class="align-center justify-center p-0 mx-auto mb-5 px-0 pl-2"
          cols="12"
          sm="2"
          md="2"
        >
          <v-btn
            class="fs-6 font-weight-black text-white bg-color text-transform-none"
            size="large"
            width="100%"
            @click="
              v$.$validate;

              if (state.question) {
                state.isUserLoggedIn
                  ? submitForm()
                  : (state.isGoogleLoginModalOpen = true);
              }
            "
          >
            {{ state.isUserLoggedIn ? "Search" : "Search and Sign In" }}
          </v-btn>
        </v-col>
      </v-container>
    </form>

    <v-dialog v-model="state.isPromptModalOpen" width="80%" height="70%">
      <v-card>
        <v-banner class="search-banner">
          <v-text-field
            v-model="state.aiPromptText"
            label="Search AI Prompts"
            variant="outlined"
            class="mx-5 mt-5"
          />
        </v-banner>

        <div class="mt-20" />

        <v-container
          v-for="(promptCategory, i) in filteredPrompt(promptsData)"
          :key="i"
          class="px-0 z-index"
        >
          <div class="text-subtitle-1 px-5 italic-bold">
            {{ promptCategory.category }}
          </div>

          <v-list-item
            v-for="(item, pi) in promptCategory.prompts"
            :key="pi"
            :value="item"
            color="primary"
            @click="
              state.question = item;
              state.isPromptModalOpen = false;
            "
          >
            <v-list-item-title class="px-5" v-text="item" />
          </v-list-item>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="state.isGoogleLoginModalOpen"
      :width="mobile ? '80%' : '30%'"
    >
      <v-card>
        <v-text-field
          v-model="state.userName"
          class="px-5 pt-5"
          label="User name"
          variant="outlined"
        />

        <GoogleLogin
          :callback="handleGoogleLogin"
          style="align-self: center; margin-bottom: 20px"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ApiService from "../../services/ApiService";
import { REDIRECT_URL } from "../../constants/Constants";
import dummyPromptData from "../../constants/aiPromptData";
import { GoogleLogin, decodeCredential } from "vue3-google-login";
import { useCookies } from "vue3-cookies";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { mobile } = useDisplay();

const { cookies } = useCookies();

const initialState = {
  question: "",
  channel: null,
  channelList: [],
  isGoogleLoginModalOpen: false,
  userName: "",
  userData: {},
  isUserLoggedIn: false,
  isPromptModalOpen: false,
  aiPromptText: "",
};

const loading = reactive({ value: false });

const state = reactive({
  ...initialState,
});

let promptsData = dummyPromptData;

const rules = {
  question: { required },
  select: { required },
  channel: { required },
};

const v$ = useVuelidate(rules, state);

async function init() {
  loading.value = true;

  const userData = cookies.get("user");
  if (userData) {
    state.userData = userData;
    state.isUserLoggedIn = true;
  }

  try {
    const { data: domainsData } = await ApiService.getAllDomains();

    domainsData?.response_data.map((domain) => {
      state.channelList.push(domain);
    });
  } catch (error) {
    console.error("Error submitting data:", error);
  } finally {
    loading.value = false;
  }
}

function filteredPrompt(promptCategoryData) {
  if (state.aiPromptText.length > 0) {
    const filteredPrompt = [];

    promptCategoryData.map((promptCategory) => {
      if (
        promptCategory.category.includes(state.aiPromptText) ||
        promptCategory.prompts.some((prompt) =>
          prompt.includes(state.aiPromptText)
        )
      ) {
        filteredPrompt.push(promptCategory);
      }
    });

    return filteredPrompt;
  }

  return promptCategoryData;
}

async function submitForm() {
  console.log(state.question, state.channel);

  // Navigate to /app
  let generalDomainName = process.env.VUE_APP_GENERAL_DOMAIN_NAME;
  let channelId = process.env.VUE_APP_GENERAL_AMA_ID;

  if (state.channel) {
    // Go to AMA channel of the selected domain
    const selectedDomainName = state.channel.name;

    // Get all channels from General domain name
    const { data: domainChannels } = await ApiService.getAllChannelByDomainName(
      generalDomainName
    );

    // Find the channel of the selected domain name
    // selectedDomainName - Coding
    // channel will find Coding-AMA
    const channel = domainChannels.response_data.find((domainChannel) =>
      domainChannel.name.includes(selectedDomainName)
    );

    channelId = channel.channel_id;
  }

  window.location.href =
    REDIRECT_URL +
    `?domain=${generalDomainName}&channel=${channelId}&question=${state.question}`;
}

async function handleGoogleLogin(response) {
  state.isGoogleLoginModalOpen = false;

  const googleUserData = decodeCredential(response.credential);

  // Check if user exist in BE or not
  const getUserRes = await ApiService.getUserById(googleUserData.sub);

  if (!getUserRes.success) {
    // Create user in BE
    await ApiService.createUser({
      user_id: googleUserData.sub,
      name: state.userName === "" ? googleUserData.name : state.userName,
      email: googleUserData.email,
      profile_picture_url: googleUserData.picture,
    });
  }

  if (
    getUserRes.success &&
    state.userName &&
    getUserRes.response?.name !== state.userName
  ) {
    // Update the user name if entered user name does not match the user response username
    await ApiService.updateUser(googleUserData.sub, {
      name: state.userName,
    });
  }

  const dataToStore = {
    id: googleUserData.sub,
    name: googleUserData.name,
    picture: googleUserData.picture,
  };

  cookies.set("user", dataToStore);

  state.userData = dataToStore;
  state.isUserLoggedIn = true;
  state.userName = "";

  // Navigate to /app
  submitForm();
}

onMounted(() => {
  init();
});
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.italic-bold {
  font-style: italic;
  font-weight: 800;
  color: #FF474C
}
.z-index-1 {
  z-index: 1;
}

.text-transform-none {
  text-transform: none;
  font-size: 14px;
}

.bg-color {
  background-color: #2f363d;
}
.z-index {
  z-index: -1;
}
.search-banner {
  position: fixed;
  padding: 0;
  margin: 0;
  border-bottom-width: 0;
}
</style>
