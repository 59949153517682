export default [
  {
    category: "Do your Research",
    prompts: [
      "I'm new to using ChatGPT and I am a [insert profession]. Generate a list of the 10 best prompts that will help me be more productive.",
      "Please tell me about [entry-level] positions in [e.g. investment banking].",
      "What is the typical starting salary for a [e.g. computer science graduate]?",
      "What certifications should a [e.g. clinical data manager hold]?",
      "What education will I need for a career in [e.g. hotel management]?",
      "What are the common mistakes a person makes on the path to becoming [dream career]? Give step-by-step instructions on how to avoid those mistakes, a detailed career path with duration.",
      "Generate a list of potential career paths for a [insert subject] student.",
      "I am a [insert profession]. Generate a list of the most powerful prompts that will help someone in my profession to get more done and save time.",
      "Suggest [e.g. five] careers for me to consider. I am [e.g. an English speaker who is fluent in Spanish and German. I enjoy helping people, seeing new places, and solving problems].",
      "What careers should I look at that combine [e.g. audio engineering with entertainment in New York]?",
      "Describe the typical career path of a [e.g. Realtor].",
      "Analyze the top performers in [insert industry]. Give me a list of the most important lessons I can learn from these top performers to boost my productivity.",
      "Suggest [3] sites to find a job in [e.g. logistics] industry.",
      "Suggest [3] apps to find a job in [e.g. programming] industry.",
      "Suggest [5] Sites for [e.g. Programming] jobs.",
      "Share some names of sites where I can find pitch decks for various companies.",
      "Tell me about the different divisions of [e.g. Boeing].",
      "Create a [200] word summary of [e.g. Google's] business.",
      "Create a [200] word summary of [e.g. Google's Cloud] business.",
      "Where can I find [e.g. Oracle's] Investor Relations Site?",
      "What's the link for [e.g. Microsoft's] 10K Report of Financial Performance?",
      "What's the link for [e.g. Microsoft's] 10Q Report of Financial Performance? (abbreviated 10K filed Quarterly)",
      "Share examples of companies in the [e.g. Trucking] Industry.",
      "What's the link for [e.g. Microsoft's Annual Report]?",
      "Summarize [e.g.P&G's] annual report in 100 words.",
      "Suggest [5] steps to research [e.g. Pepsi].",
    ],
  },
  {
    category: "Create/Refine Your Resumé and Cover Letter",
    prompts: [
      "Share links to the top websites that can help me write my resume. I am looking for a [e.g. coding] job.",
      "Help me write my resume. I have [e.g. 5 years] experience as a [e.g. Python Programmer]; [2 years] as a [Sales Engineer] [add more positions or education].",
      "Create a template for an [insert job e.g. accountant] resume with ten years of experience. Include sections for accomplishments, skills and certifications.",
      "Create a one-page resume for an [e.g. executive assistant position], highlighting [impressive organizational and administrative] skills.",
      "Develop a professional summary statement that effectively summarizes experience in [marketing and communications].",
      "Describe an impressive accomplishment for a [medical assistant] role that sets this applicant apart from other candidates.",
      "Summarize a unique skill set for an [HR] manager role that reflects the candidate’s ability to lead and motivate teams.",
      "Write an education section for a resume emphasizing a candidate’s passion for [data science and analytics].",
      "Write a resume using the following information [paste info here].",
      "Write a resume using the following information. Include three bullet points for three key achievements. [Paste information here you can paste e.g. your LinkedIn 'About' section].",
      "Read [paste resume here] resume, identify any omissions, and tell me how to improve it.",
      "Generate a summary that showcases my [e.g. programming experience migrating COBOL systems to modern platforms].",
      "Share links to the top websites that can help me write my cover letter. I am looking for a [e.g. coding] job.",
      "Help me write a cover letter. I have [e.g. 5 years] experience as a [e.g. Python Programmer]; [2 years] as a [Sales Engineer].",
      "Help me write a clear and concise email to [recipient] regarding [e.g. programming job] at [Company].",
      "Give me three tips for effectively communicating [specific topic or message] in an email.",
      "Design a modern-looking cover letter tailored to a [insert position] position. Showcase the applicant’s creativity and technical expertise.",
      "Develop a compelling elevator pitch for an [insert type of job] job that grabs the attention of potential employers.",
      "Write a LinkedIn post about the importance of [e.g. networking] in the corporate world.",
      "Create a personalized cover letter tailored to a job in [customer service], expressing the candidate’s enthusiasm and commitment to the role.",
      "Write a 200-word introduction for a resume highlighting the candidate’s qualifications and accomplishments in [law].",
      "Create a message to connect with a professional at [Company] on LinkedIn, discussing my interest in the [Position] and how my background in [Specific field/technology] makes me a strong candidate.",
      "Write a 200-word summary focusing on my proven ability to create [e.g. sales leads and increase revenue].",
      "Write a cover letter for a job application for the position [e.g. Editorial Assistant] at the company [NY Times]. Address the letter to [name], HR Director.",
      "Change [paste letter here] cover letter to an email message with a maximum length of [175] words.",
      "Tell me [three] ways to improve this [paste email here] email message.",
      "Read this [paste here] cover letter and tell me how to enhance it.",
      "Summarize [paste material]. Give me a list of bullet points with key insights and the most important facts.",
      "Improve [paste here] this cover letter.",
    ],
  },
  {
    category: "Prep for the Interview",
    prompts: [
      "Share [5] examples of interview questions for [job/subject].",
      "I want you to act as an [e.g. Programming] Interview expert and ask me [3] questions about [e.g. Python].",
      "I want you to act as an [e.g. Enterprise Sales] expert and give me [3] recommendations for [e.g. Enterprise Selling].",
      "Can you provide me with some exercises to practice [e.g. Kotlin Programming Interview]?",
      "Come up with a multiple-choice exercise to test knowledge of [Role I am interviewing for].",
      "Share examples of enhancing [e.g. team productivity] by implementing [e.g. SCRUM] methodology.",
      "Describe an impressive accomplishment for a [medical assistant] role that sets this applicant apart from other candidates.",
      "Share [3] examples of [e.g. teamwork] in the [e.g. auto industry].",
      "What interests you about this [paste role description here] role?",
      "Why do you want to work for [insert company name]? Share [3] reasons.",
      "Use this [e.g. paste text from LinkedIn 'about me' here or your entire resume!] to share [3] examples of what motivates me?",
      "Use this [e.g. paste text from LinkedIn 'about me' here or your entire resume!] to share [3] examples of what I can bring to [e.g. Uber].",
      "Use this [e.g. paste text from LinkedIn 'about me' here or your entire resume!] to share [3] examples of my long term career goals.",
      "Use this [e.g. paste text from LinkedIn 'about me' here or your entire resume!] to share [3] examples of me being a team player.",
      "Use this [e.g. paste text from LinkedIn 'about me' here or your entire resume!] to share [3] examples of why I am leaving my current job?",
      "Use this [e.g. paste text from LinkedIn 'about me' here or your entire resume!] to share [3] examples of what I can do better than others?",
      "Use this [e.g. paste text from LinkedIn 'about me' here or your entire resume!] to share [3] examples of when I was most satisfied with my job?",
      "Suggest [3] websites for interview questions.",
      "Create five questions for an informational interview with a [e.g. successful entrepreneur] to learn more about their journey and tips for success.",
      "Act as a job interviewer for a [e.g. Google]. I am interviewing for a [e.g. Programmer position]. Help me come up with questions I should ask the interviewer at the end of the interview.",
      "Why did you decide to work for [company]? Please share [3] reasons.",
      "What is [1] challenge you face in your job as [e.g. crypto analyst]?",
      "What is different about working at [Company A] versus [Company B]? Share [3] examples.",
      "How has [company] overcome challenges that come with remote work?",
      "Share [3] examples of next steps after a job interview.",
    ],
  },
  {
    category: "Learn and Improve (Find a better job!)",
    prompts: [
      "Create a beginner's guide to ChatGPT. Topics should include prompts, priming, and personas. Include examples. The guide should be no longer than [500] words.",
      "I want you to act as an [subject] expert and give me recommendations for [specific question].",
      "My goal is to learn [Skill]. Make a list of the most important things I need to know that will allow me to master this topic.",
      "What are some key terms I should know about [TOPIC]? Make a list with a short, simple definition of each term.",
      "I want to learn [TOPIC]. Give me step-by-step instructions on how to learn [SKILL]. Start with the basics and move on to the more difficult ones. Keep in mind that I'm a beginner.",
      "Can you explain the concept of [TOPIC] in simple terms? Summarize the main principles and illustrate with examples to make it easier to understand.",
      "Can you provide me with some exercises to practice [skill]?",
      "Suggest 3 apps for learning [e.g. Swift].",
      "Come up with a multiple-choice exercise to test knowledge of [skill].",
      "Help me weigh the pros and cons of [decision or choice], and provide a suggestion based on the analysis.",
      "Write a beginner-friendly, step-by-step guide on how to [perform a specific task or achieve a certain goal].",
      "Identify the 20% of [topic or skill] that will yield 80% of the desired results and provide a focused learning plan to master it.",
      "Guide me through a visualization exercise to help me internalize [topic or skill] and imagine myself successfully applying it in real-life situations.",
      "Explain [topic or skill] in the simplest terms possible as if teaching it to a complete beginner. Identify gaps in my understanding and suggest resources to fill them.",
      "Design a deliberate practice routine for [topic or skill], focusing on my weaknesses and providing regular feedback for improvement.",
      "Develop three examples of figurative language that could be used to enhance a descriptive essay about a [e.g. magical forest].",
      "Rephrase [this text] to be more conversational.",
      "Tell me what else you need to do [this].",
      "Come up with [10] ways to improve memory and recall while studying for exams.",
      "List note-taking techniques for a chemistry lecture.",
      "Suggest [10] Chrome extensions for students designed to improve productivity while studying.",
      "Within the realm of [broader subject, e.g., “philosophy”], I’m intrigued by [specific interest, e.g., “existentialism”]. Could you recommend topics that bridge it with [another field or theme, e.g., “modern technology”] in the context of [specific region or era, e.g., “21st-century Europe”]?",
      "I’m exploring the field of [broader subject, e.g., “psychology”]. Could you suggest some topics that intersect with [specific interest, e.g., “child development”] and are relevant to [specific context or region, e.g., “urban settings in Asia”]?",
      "Suggest some potential topics within the field of [your broader subject] related to [specific interest or theme].",
      "I am having difficulty learning [topic]. Help me understand it better by using first principles thinking.",
      "I am a beginner interested in [topic]. Can you give me [e.g. 3] project ideas to strengthen my skills?",
      "Give me a [5 question] quiz that will help me learn [topic].",
      "Break down [topic] into smaller, easier to understand parts.",
    ],
  },
  {
    category: "Need a Certification? Prep for a Test",
    prompts: [
      "My goal is to learn [Subject]. Make a list of the most important things I need to know that will allow me to master this topic.",
      "Define [e.g. Pathos] and provide an example that can be used in [e.g. SAT Literature Test]. The definition should be complete, but easy to understand. Explain any complicated words if necessary.",
      "Describe and explain in simple words the relationship between [CONCEPT 1] and [CONCEPT 2].",
      "What are some key terms I should know about [TOPIC]? Make a list with a short, simple definition of each term.",
      "Describe the main points of [e.g. book, theorem, movie, poem] in a few sentences.",
      "Suggest 3 apps for preparing for [e.g. GMAT].",
      "Provide a brief quiz that teaches me [desired learning topic].",
      "Simplify [subject] for easy understanding. Explain it as if I'm just starting out.",
      "Create a study plan that mixes different topics or skills within [subject area] to help me develop a more robust understanding and facilitate connections between them.",
      "Transform key concepts or lessons from [topic or skill] into engaging stories or narratives to help me better remember and understand the material.",
      "Help me create mental models or analogies to better understand and remember key concepts in [topic or skill].",
      "Provide me with a series of challenging questions or problems related to [topic or skill] to test my understanding and improve long-term retention.",
      "Suggest various learning resources (e.g., videos, books, podcasts, interactive exercises) for [topic or skill] that cater to different learning styles.",
      "Design a spaced repetition schedule for me to effectively review [topic or skill] over time, ensuring better retention and recall.",
      "Provide me with a [e.g 5] challenging questions or problems on the [e.g. SAT Math II test].",
      "What are the most important facts, dates or formulas related to [topic]. Help me create a memorization technique to remember them easily.",
      "Act as an [e.g. SAT Math] expert and provide guidance for effective problem-solving strategies and time management techniques for the [e.g. Math] section.",
      "Create a study plan for the [e.g. GRE] exam.",
      "Explain the concept of [e.g. photosynthesis].",
      "Fact-check the statement: [e.g. water boils at 200 degrees Celsius].",
      "Break down and explain the key components of the problem statement: [problem statement].",
      "Summarize the main points of [text or article].",
      "Assess my exam readiness for [exam subject] by asking multiple choice questions.",
      "Generate practice questions on [specific topic].",
      "Write frequently asked questions (FAQs) about [specific topic].",
      "Share exam tips for achieving a high score on [e.g. LSAT].",
      "Identify and define the key terms related to [specific topic].",
      "Analyze the cause and effect relationships of [specific event or phenomenon].",
      "Predict the potential outcomes and consequences of [specific scenario or action].",
      "Create multiple-choice questions with answer and explanation on [specific topic/test].",
      "Apply the scientific method to investigate [specific scientific question or hypothesis].",
      "Evaluate the strength and weaknesses of the argument that [specific claim or statement].",
      "Explain the historical context surrounding [specific event or period].",
      "Analyze the data and graphs related to [specific dataset or topic].",
      "Act as a coach for [specific subject] and provide strategies for improving performance in that subject.",
      "Cite your sources for [this] information.",
    ],
  },
  {
    category: "Helpful Prompt Modifiers",
    prompts: [
      "Formal",
      "Informal",
      "Short",
      "Long",
      "Generate",
      "Write XX words",
      "Act as a <insert job title>",
      "Make a table of",
      "How do I create",
      "List <insert number> ways",
      "How can you effectively",
      "Create a bullet list of",
      "What techniques can I use",
      "Explain the importance of",
      "Can you devise",
      "Suggest tools I can use",
      "Scan the following text for",
      "Look for errors in",
      "How can I optimize",
      "Can you recommend any",
      "What would be the most beneficial approach to",
      "Is there any way to simplify",
      "How can I make <insert phrase> faster",
      "Include <insert keyword> in this blog post",
      "Are there any tips or tricks I can use for",
      "Write a post specifically for",
    ],
  },
];

// export default [
//   {
//     category: "Learn (Expert)",
//     prompts: [
//       "Act as an expert in [Topic]",
//       "I want you to act as an [subject] expert and give me recommendations for [specific question].",
//       "I want you to act as an expert in [TOPIC] and give me recommendations for [SPECIFIC QUESTION.]",
//       "I want you to act as a research assistant and provide me with 5 reliable sources to learn about [topic].",
//       "I will provide you with an argument or opinion of mine. I want you to criticize it as if you were [musk]",
//       "I want you to act as a teacher and give me a definition of [term].",
//       "Simplify [subject] for easy understanding. Explain it as if I'm just starting out.",
//     ],
//   },
//   {
//     category: "Learn (Method)",
//     prompts: [
//       "My goal is to learn [Skill].  Make a list of the most important things I need to know that will allow me to master this topic.",
//       "Define [TERM] and proivde an example that can be used in everyday life. The definition should be complete, but easy to understand. Explain any complicated words if necessary",
//       "Describe and explain in simple words the relationship between [CONCEPT 1] and [CONCEPT 2.]",
//       "What are some key terms I should know about [TOPIC]? Make a list with a short, simple definition of each term",
//       "I want to learn [TOPIC.] Give me step-by-step instructions on how to learn [SKILL.] Start with the basics and move on to the more difficult ones. Keep in mind that I'm a beginner.",
//       "Can you explain the concept of [TOPIC] in simple terms? Summarize the main principles and illustrate with examples to make it easier to understand.",
//       "List some other approaches to [topic] that I could explore.",
//       "Come up with a multiple-choice exercise to test knowledge of [skill].",
//       "Write a beginner-friendly, step-by-step guide on how to [perform a specific task or achieve a certain goal].",
//       "Provide a brief quiz that teaches me [desired learning topic].",
//       "Create a study plan that mixes different topics or skills within [subject area] to help me develop a more robust understanding and facilitate connections between them.",
//       "Come up with 10 ways to improve memory and recall while studying for exams.",
//       "List note-taking techniques for a chemistry lecture.",
//       "Suggest 10 Chrome extensions for students designed to improve productivity while studying.",
//       "Within the realm of [broader subject, e.g., “philosophy”], I’m intrigued by [specific interest, e.g., “existentialism”]. Could you recommend topics that bridge it with [another field or theme, e.g., “modern technology”] in the context of [specific region or era, e.g., “21st-century Europe”]?",
//       "I’m exploring the field of [broader subject, e.g., “psychology”]. Could you suggest some topics that intersect with [specific interest, e.g., “child development”] and are relevant to [specific context or region, e.g., “urban settings in Asia”]?",
//       "Suggest some potential topics within the field of [your broader subject] related to [specific interest or theme].",
//     ],
//   },
//   {
//     category: "Productivity",
//     prompts: [
//       "Describe the main points of [book] in a few sentences",
//       "With [skills] and [budget], give me 5 ideas, budgets, and step-by-step instructions for every idea on how to earn more money",
//       "Help me weigh the pros and cons of [decision or choice], and provide a suggestion based on the analysis.",
//     ],
//   },
//   {
//     category: "Learn/Skill",
//     prompts: [
//       "Can you provide me with some exercises to practice [skill]",
//       "Suggest me 3 apps for learning e.g. [topic]",
//       "Identify the 20% of [topic or skill] that will yield 80% of the desired results and provide a focused learning plan to master it.",
//       "Guide me through a visualization exercise to help me internalize [topic or skill] and imagine myself successfully applying it in real-life situations",
//       "Explain [topic or skill] in the simplest terms possible as if teaching it to a complete beginner. Identify gaps in my understanding and suggest resources to fill them.",
//       "Design a deliberate practice routine for [topic or skill], focusing on my weaknesses and providing regular feedback for improvement.",
//       "Transform key concepts or lessons from [topic or skill] into engaging stories or narratives to help me better remember and understand the material.",
//       "Help me create mental models or analogies to better understand and remember key concepts in [topic or skill].",
//       "Provide me with a series of challenging questions or problems related to [topic or skill] to test my understanding and improve long-term retention.",
//       "Suggest various learning resources (e.g., videos, books, podcasts, interactive exercises) for [topic or skill] that cater to different learning styles.",
//       "Design a spaced repetition schedule for me to effectively review [topic or skill] over time, ensuring better retention and recall.",
//     ],
//   },
//   {
//     category: "Biz Productivity",
//     prompts: [
//       "Enhance team productivity in [business] by implementing the SCRUM methodology",
//       "Utilize the Golden Circle Model to create a powerful brand for [product or service].",
//       "Develop a crisis management plan for [business] to effectively respond to and mitigate potential crises, safeguard brand reputation, and ensure business continuity.",
//       "What are the common mistakes a person makes on the path to becoming [dream career]? Give step-by-step instructions on how to avoid those mistakes, a detailed career path with duration.",
//       "Create a marketing strategy for [my product]. I have about a [available budget] marketing budget and need to reach [target audience].  Build a table with the ROI expectations by channel.",
//       "Generate a creative social media content calendar for the next week for our [product/company] on how [desired outcome of our customers]",
//       "Write a marketing campaign outline that uses [emotional appeal] to persuade [ideal customers] to take action and purchase [product/service].",
//       "Create a catchy and memorable tagline or slogan for my [brand, product, or campaign] that encapsulates its essence and appeals to the target audience.",
//       "Compose a captivating 30-second elevator pitch for my [business, product, or idea], highlighting its main selling points.",
//       "Suggest a brief and focused format for our regular team check-ins, with a focus on discussing progress, addressing issues, and fostering collaboration. Provide an example of how this format would look in practice",
//       "Create compelling sales copy for my [product or service], focusing on its unique features and benefits.",
//     ],
//   },
//   {
//     category: "Communication",
//     prompts: [
//       "Help me draft a clear and concise email to [recipient's name] regarding [subject]. Include necessary details and a call to action, ensuring effective communication.",
//       "Give me three tips for effectively communicating [specific topic or message] in a conversation, presentation, or meeting.",
//     ],
//   },
//   {
//     category: "Career",
//     prompts: [
//       "Create a template for an [insert job e.g. accountant] resume with ten years of experience. Include sections for accomplishments, skills and certifications.",
//       "Design a modern-looking cover letter tailored to a [insert position] position. Showcase the applicant’s creativity and technical expertise.",
//       "Develop a compelling elevator pitch for an [insert type of job]  job that grabs the attention of potential employers.",
//       "Generate a list of potential career paths for a [insert subject] student.”",
//       "Write a LinkedIn post about the importance of [networking] in the corporate world.",
//     ],
//   },
//   {
//     category: "Resume",
//     prompts: [
//       "Create a one-page resume for an [executive assistant position], highlighting impressive organizational and administrative skills.",
//       "Develop a professional summary statement that effectively summarizes experience in [marketing and communications]",
//       "Create a personalized cover letter tailored to a job in [customer service], expressing the candidate’s enthusiasm and commitment to the role.",
//       "Describe an impressive accomplishment for a [medical assistant] role that sets this applicant apart from other candidates.",
//       "Summarize a unique skill set for an [HR] manager role that reflects the candidate’s ability to lead and motivate teams.",
//       "Write an education section for a resume emphasizing a candidate’s passion for [data science and analytics]",
//       "Write a 200-word introduction for a resume highlighting the candidate’s qualifications and accomplishments in [law]",
//     ],
//   },
//   {
//     category: "Marketing",
//     prompts: [
//       "Generate five creative strategies for launching a new [cocoa butter lotion] product. The product is made with natural ingredients in small batches. The target audience is mothers aged 30-40.",
//       "Design a comprehensive social media campaign for launching a new [Greek restaurant chain], including ideal post frequency and creative content ideas. The restaurant is located in [Vancouver, BC], and its specialty dish is [lemon potatoes].",
//       "Generate a list of 10 customer outreach topics that can be used to engage with potential buyers of a product or service.",
//       "Develop a campaign slogan for an ecommerce store selling [handmade jewelry] that conveys the company’s sense of style, quality and values.",
//       "Write five email subject lines that are designed to increase open rates for an [e-commerce store]",
//       "Generate suggestions for five influencer campaigns that will help a [sustainable clothing] company reach their target market of Gen Z and millennial customers.",
//     ],
//   },
//   {
//     category: "Writing",
//     prompts: [
//       "Develop a compelling thesis statement for an argumentative essay about [climate change]",
//       "Generate five plot points for a [mystery] novel set in [small-town America]. The main character is a [retired detective] who is solving the murder of an old friend.",
//       "Create three characters for a science-fiction novel set in space. The story focuses on their quest to find a new home and save their species from extinction.",
//       "Develop a list of 10 potential titles for a non-fiction book about [leadership]. The book’s author has experience leading a team in the healthcare industry.",
//       "Develop three examples of figurative language that could be used to enhance a descriptive essay about a [magical forest].",
//       "Create five questions for an informational interview with a successful entrepreneur to learn more about their journey and tips for success.",
//       "Generate five unique topics for a magazine article about trends in [education]. Create three compelling arguments.",
//       "Brainstorm ten potential titles for a [romantic comedy] about two coworkers who fall in love while working at a tech startup.",
//       "[This] a draft of my product description. Can you suggest edits to make it more persuasive and engaging?",
//       "Give me three different headlines for a blog post about [zero waste living]",
//       "Rephrase [this text] to be more conversational",
//     ],
//   },
//   {
//     category: "Small Biz",
//     prompts: [
//       "Design an employee onboarding program for a [pet food] store that includes training, job responsibilities, expectations, and closing duties.",
//       "Generate five ideas for cost-effective [print marketing] strategies that will help promote a [local landscaping] business.",
//       "Create three product bundles that maximize value for customers of a [home décor] boutique.",
//       "Design an effective customer loyalty program for a [pharmacy] that rewards repeat customers.",
//       "Write a mission statement for an online [yoga studio] that expresses the company’s commitment to providing accessible and affordable classes.",
//       "Generate five email subject lines demonstrating value for subscribers of a [subscription box] service.",
//       "Write three customer service scripts designed to resolve common customer inquiries quickly and efficiently.",
//     ],
//   },
//   {
//     category: "Content Creation",
//     prompts: [
//       "Design five unique topics for Instagram stories that will engage followers of a fitness brand.",
//       "Create three Pinterest board ideas that showcase the benefits of a nutrition coaching program.",
//       "Develop ten blog post ideas that promote an event planning business’s services and expertise.",
//       "Generate five creative TikTok video scripts for promoting a [vegan bakery’s] product line.",
//       "Develop five potential YouTube video ideas for introducing a new [beauty brand] to viewers.",
//       "Create three compelling topics for Twitter or Threads about [personal finance] aimed at young professionals.",
//       "Design a content calendar for Facebook that includes posts, polls, tagging opportunities and video content.",
//       "Generate five creative ideas for a podcast series focusing on [real estate development in Toronto]",
//     ],
//   },
//   {
//     category: "Web Dev.",
//     prompts: [
//       "Create three navigation menus that are optimized for user experience. The menus should be designed to guide users through a [doctor’s] website quickly and seamlessly.",
//       "Design three calls-to-action that can be used to increase conversions for an [e-commerce store]",
//       "Design two optimized contact forms to collect emails from [B2B] clients in the [automotive manufacturing industry]",
//       "Write five tips for using an SEO plugin correctly to maximize website visibility and rankings.",
//       "Create a landing page outline for a [mobile banking] app designed to attract younger users.",
//       "Write conversion-focused copy for two content pages that can be used to showcase a [luxury car dealership’s] inventory and services.",
//       "Develop four ideas for interactive features that would enhance the user experience of a [restaurant’s] website.",
//       "Write five tips for creating an intuitive and attractive homepage that will help draw visitors to a [real estate] company’s website.",
//     ],
//   },
//   {
//     category: "Social Media Marketing",
//     prompts: [
//       "Design five creative hashtags that can be used to promote a new [clothing line] on Instagram.",
//       "Write three captions ideas for Instagram, promoting a [coffee shop’s] latest feature [beverage, the Mocha Matcha Latté].",
//       "Create two promotional posts that highlight the advantages of using a [beauty] subscription box service.",
//       "Develop five ideas for using polls on Facebook to engage users and encourage them to learn more about a home decoration business.",
//       "Create a three-month content calendar for an animal rescue organization. Focus on Instagram, Facebook, and TikTok. The verticals the rescue wants to focus on include raising awareness, adoption stories, and foster highlights.",
//       "Develop a series of Tweets or Threads about the importance of sustainability in fashion. This series will be posted by a sustainable yoga-wear brand.",
//       "Design five social media ads that promote the features of a new fitness app. The ads should target a younger, tech-savvy audience.",
//       "Generate four ideas for using Stories on Instagram to showcase the benefits of using a meal delivery service.",
//       "Write a Twitter post, max 280 characters, announcing a summer sale for a clothing brand.",
//     ],
//   },
//   {
//     category: "Education",
//     prompts: [
//       "Design a math lesson plan for grade 4 students focusing on basic algebra.",
//       "Create three activities to teach English nouns to adult non-native speakers.",
//       "Develop five discussion questions about world history that focus on the events of the 20th century.",
//       "Write three science experiments that illustrate the properties of water.",
//       "Create a worksheet to teach French verb conjugation to middle school students.",
//       "Generate five essay prompts focusing on the impacts of climate change in Canada.",
//       "Develop two interactive activities to teach older elementary school children about the solar system.",
//       "Generate four discussion questions about the current state of US politics for use with a group of fifth graders.",
//       "Design three quiz questions focusing on world geography for a group of eighth graders.",
//     ],
//   },
//   {
//     category: "Project Mgmt.",
//     prompts: [
//       "Create a list of tasks that need to be completed, along with a timeline for their completion, in order to launch a redesigned website for a dental office.",
//       "Generate five ideas for using agile methodology to manage an online marketing campaign for a local record store.",
//       "Develop a project plan for creating and launching an email newsletter campaign targeting new customers of a hardware store.",
//       "Write four KPIs that can be used to measure the success of a mobile banking app development project.",
//       "Create an outline for developing comprehensive user documentation for a software as a service (SaaS) product.",
//       "Design five content buckets that can be used to organize the content of a print magazine for an international audience.",
//       "Write three steps for setting up a project tracking system that will help manage workflow and productivity.",
//       "Generate four ideas for using scrum methodology to develop an interactive online course focused on personal finance management.",
//     ],
//   },
//   {
//     category: "Healthcare",
//     prompts: [
//       "Create three blog posts that explain the importance of preventive care and wellness exams.",
//       "Design four video script ideas focusing on common medical procedures, such as vaccinations or physical therapy.",
//       "Generate five infographic ideas for explaining the basics of mental health and how to cope with stress.",
//       "Write a series of Facebook posts providing visitors to a medical website with tips on healthy eating habits.",
//       "Develop five quiz questions that can be used to test patients’ knowledge about the benefits of choosing an in-network provider.",
//       "Design two email campaigns focusing on common skin conditions and how to look after aging skin.",
//       "Create an acronym for sun safety that parents can use to help remind their children of important tips for sun protection.",
//       "Write an outline for a healthcare social media policy that can be used to guide employees when posting on behalf of a medical practice.",
//     ],
//   },
//   {
//     category: "Finance",
//     prompts: [
//       "Create five blog posts that explain the advantages of investing in stocks and mutual funds.",
//       "Develop three Facebook posts about different types of credit cards and how to choose one that is right for you.",
//       "Write a Tweet focusing on the importance of financial literacy and managing debt.",
//       "Generate five checklist items for creating a successful retirement plan in your twenties.",
//       "Create an email outline that explains credit scores and how to improve them.",
//       "Generate five ideas for using Instagram to promote a financial literacy program for high school students.",
//     ],
//   },
//   {
//     category: "Music",
//     prompts: [
//       "Write a hook for a pop-rock song about the struggles of being an artist.",
//       "Generate five soundbite ideas for interviews with music industry professionals.",
//       "Design six-chord progressions to create a moody jazz instrumental track.",
//       "Create four lyric ideas that focus on the power of music and the joys of performing.",
//       "Modify the following chord progression to make it more like a classic rock ballad:[insert progression]",
//       "Write the lyrics to a song titled “The Beat Of A Different Drummer” about discovering your own path in life. The song should have five verses and one chorus.",
//       "Create an outline for a music video that tells the story of a romance between two people from different neighborhoods.",
//       "Develop four ideas for using audio technology to mix a live recording of an acoustic performance.",
//       "Provide Python code that writes a simple tune to a MIDI file.",
//     ],
//   },
//   {
//     category: "HR",
//     prompts: [
//       "Write five interview questions for a senior software engineer position that explore a candidate’s interpersonal skills.",
//       "Develop two activities to help orient new employees to their roles and responsibilities at a tech startup.",
//       "Create three posts promoting a content manager job opening on social media platforms such as Twitter and LinkedIn. Note that the ideal candidate should have experience in content creation, strategy, and execution.",
//       "Generate four ideas for using video to help new employees at a grocery store learn about the company culture and core values.",
//       "Design five ice-breaker questions that can be used with a group of new bookkeeping hires during an orientation session.",
//       "Create an outline for an employee handbook that covers topics such as dress code, vacation policies, and employee benefits.",
//       "Write four performance evaluation criteria for an entry-level customer service representative role.",
//       "Develop two training modules that will help new hires at a marketing agency learn about digital advertising tools and concepts.",
//     ],
//   },
//   {
//     category: "Fitness",
//     prompts: [
//       "Write a blog post that provides readers with five tips for creating an effective workout routine.",
//       "Design four exercises that focus on strength-building and can be done at home with minimal equipment.",
//       "Generate five ideas for incorporating mindfulness into everyday life, such as taking breaks from work or dedicating time to relaxation.",
//       "Develop four tips for how to coach someone through a difficult personal situation without giving unsolicited advice.",
//       "Create an outline for a healthy eating program that focuses on whole foods and avoiding processed products.",
//       "Write a weekly meal plan for a client who is gluten intolerant and vegetarian. Meals they enjoy cooking include stir-fries and curries.",
//       "Design two exercises that focus on core stability and stretching, suitable for a beginner-level fitness routine.",
//     ],
//   },
//   {
//     category: "Art",
//     prompts: [
//       "Pretend you are creating a still-life painting featuring a vase of flowers. Generate five prompts that AI art tools like Midjourney can use to generate visuals for the painting.",
//       "Create four AI art prompts that could be used to create a landscape scene with trees and mountains.",
//       "Make a list of six concepts that can be used to create a portrait with the help of AI art tools.",
//       "Write an AI art prompt that will generate a surreal image of an owl with bright colours and geometric shapes.",
//       "Design three prompts for an AI art piece inspired by the works of Piet Mondrian.",
//       "Develop five prompts for creating AI art using images from outer space, such as stars and galaxies.",
//       "Create two prompts for an abstract painting of a boat featuring warm tones and soft lines.",
//       "Write an AI art prompt that will generate a futuristic street scene where people are eating exotic space foods from street stalls.",
//     ],
//   },
//   {
//     category: "Cooking",
//     prompts: [
//       "Generate six ideas for vegetarian tacos that aren’t just beans and rice.",
//       "Design four healthy snacks that use three ingredients or less.",
//       "Create a meal plan for one week of vegan lunches, with a focus on creative flavour combinations.",
//       "Write five slow cooker recipes that can be prepared in one hour or less.",
//       "Develop three recipes for a weekend brunch that are easy to make but still look impressive.",
//       "Write me a dinner recipe tonight. All I have in my fridge are potatoes, eggs, spinach, cheese, and spices like turmeric and cayenne pepper.",
//       "Create a delicious vegan dessert recipe that can be enjoyed by someone with a nut allergy.",
//       "Design a meal plan for one week of dinners that are high in protein and low in carbohydrates.",
//     ],
//   },
//   {
//     category: "Non-Profit",
//     prompts: [
//       "Write a blog post about the importance of volunteerism and how it can make a difference in people’s lives.",
//       "Design five fundraising ideas to support an environmental conservation initiative.",
//       "Create three emails that will encourage readers to donate to a mental health charity.",
//       "Generate four ideas for using video content to promote a youth empowerment program.",
//       "Develop five social media posts that highlight the work of an animal rescue organization.",
//       "Write an article about the power of community and how it can help people in need.",
//       "Create two tips for housing nonprofits on developing a successful fundraising strategy.",
//       "Create a Facebook event description for an art gallery charity gala that will attract donations from other local artists.",
//     ],
//   },
//   {
//     category: "Real Estate",
//     prompts: [
//       "Write a blog post about the advantages of living in a city versus living in the suburbs.",
//       "Design four tips for renters on how to find an affordable apartment in their area.",
//       "Create five social media posts that showcase a luxury beachfront property.",
//       "Generate three ideas for using video content to promote a new affordable housing development.",
//       "Pretend you are creating a digital brochure for a high-end condo building. Write five features that would be included in the brochure.",
//       "Create an outline for a blog post discussing the latest interest hikes and what it means for Canadian mortgage holders.",
//       "Write a series of questions first-time home buyers should ask their real estate agent.",
//       "Develop four tips for how to stage a home in order to make it more attractive to potential buyers.",
//       "Create an e-book guide that provides readers with information about the different types of mortgages available in Tenessee.",
//     ],
//   },
//   {
//     category: "Law",
//     prompts: [
//       "Write a blog post about the importance of understanding the legal implications of a contract before signing it.",
//       "Design four tips for entrepreneurs on how to protect their intellectual property rights.",
//       "Role play a conversation between two recent divorcees and their attorneys, who are discussing a potential settlement. Pretend that divorcee number one wants to retain majority financial control of the couple’s shared assets, while divorcee number two wants a more equitable distribution. How should their lawyers respond?",
//       "Create five social media posts that explain the basics of criminal law in easy-to-understand language.",
//       "Generate three ideas for using video content to answer commonly asked questions about consumer rights.",
//       "Generate five questions for private investigators might ask during an investigation into suspected tax fraud.",
//       "Create three emails that will remind clients about the upcoming deadline for filing their taxes on time.",
//       "Develop four tips for how small business owners can protect themselves from potential lawsuits.",
//     ],
//   },
//   {
//     category: "Government",
//     prompts: [
//       "Write a blog post outline about the benefits of participating in local government elections.",
//       "Design four tips for newly elected officials on how to become effective public servants.",
//       "Create five social media posts that explain the basics of taxation in easy-to-understand language.",
//       "Write five questions for citizens to ask their elected representatives, in order to better understand the policy decisions related to a new development project.",
//       "Create an email template that will be used to remind citizens of the upcoming deadline for filing their taxes on time.",
//       "Develop four tips for how rural government agencies can use technology to improve citizen services.",
//       "Create a series of infographic ideas that can explain the different stages involved in the legislative process.",
//     ],
//   },
//   {
//     category: "Sales",
//     prompts: [
//       "Write a blog post about the importance of understanding customer needs before making a sales pitch.",
//       "Pretend you’re a customer looking to add a social media management SaaS product to your tech stack. What pain points are you experiencing? What barriers do you have to bring this new product on?",
//       "If you were creating a sales script for a new fashion-forward life jacket product launch, what three key messages would you include?",
//       "Design four tips for cellular service provider cold calling that will help increase the success rate of callers.",
//       "Generate three ideas for using video content to show prospects how easy it is to use a new online payment platform.",
//       "Create a four-week sales strategy outline for a chef’s knife company looking to increase its market share in Japan.",
//       "Write an elevator pitch for a productivity app that offers users a way to plan, track and manage tasks using their smartphone.",
//       "Develop five social media posts that highlight the value of a cloud-based customer relationship management (CRM) system.",
//     ],
//   },
//   {
//     category: "Email Marketing",
//     prompts: [
//       "Design four tips for creating effective CTA buttons that will drive more conversions in emails.",
//       "Write four email subject line options for an email announcing a new location for a skincare and naturopathy clinic.",
//       "Create an email outline that will describe how a streaming subscription service’s new offline downloading features can save customers time and money.",
//       "Pretend you are the CEO of an outdoor fashion brand. You’ve subscribed to a competitive analysis newsletter and you’ve received your first email. What three key messages should the email include to pique your interest?",
//       "Write an email template that can be used to boost customer loyalty for a physiotherapy clinic by encouraging them to refer their friends and family members.",
//       "Create an automation flow for an online clothing store, targeting customers who might be interested in sustainability, organic fabrics and ethical fashion.",
//       "Write a short feedback survey for a wedding planner that will be included in a thank you note to the newlyweds. Keep it short and simple, and use a grateful and upbeat tone.",
//     ],
//   },
//   {
//     category: "UX",
//     prompts: [
//       "Design four tips for improving the onboarding process for a mobile app that helps users track their budget.",
//       "Write an article about how to design a website’s pages and menus in order to maximize user engagement.",
//       "Write a series of survey questions that can be used to gain insight into what features users value most in a food delivery app.",
//       "Generate three tips for designing a visual search interface that will make finding products faster and easier.",
//       "Write out the most important features to include when designing a new email template for a B2C fashion brand.",
//       "Develop four steps to consider when designing an AI assistant for a music streaming service.",
//       "Create five micro-interactions that will help users navigate through a new ecommerce website.",
//     ],
//   },
//   {
//     category: "Customer Support",
//     prompts: [
//       "Design four tips for improving the onboarding process for a mobile app that helps users track their budget.",
//       "Write an article about how to design a website’s pages and menus in order to maximize user engagement.",
//       "Write a series of survey questions that can be used to gain insight into what features users value most in a food delivery app.",
//       "Generate three tips for designing a visual search interface that will make finding products faster and easier.",
//       "Write out the most important features to include when designing a new email template for a B2C fashion brand.",
//       "Develop four steps to consider when designing an AI assistant for a music streaming service.",
//       "Create five micro-interactions that will help users navigate through a new ecommerce website.",
//     ],
//   },
//   {
//     category: "Data Science",
//     prompts: [
//       "Create an infographic that explains how to use predictive algorithms to reduce customer churn rates.",
//       "Write a blog post outlining the basics of predictive analytics and its applications.",
//       "Design four tips for setting up a machine learning system that can accurately detect customer sentiment in real-time.",
//       "Pretend you are the data scientist at a large engineering firm. You need to develop an algorithm to predict which high-value customers will likely purchase a new product. What three features will be most important in determining purchase likelihood?",
//       "Generate three ideas for using natural language processing (NLP) to automatically generate insights from customer reviews and feedback.",
//       "Write a tutorial-style video script for how to use linear regression to forecast sales figures.",
//     ],
//   },
//   {
//     category: "E-Commerce",
//     prompts: [
//       "Design four tips for optimizing the checkout process for a linen bedsheet company to reduce cart abandonment rates.",
//       "Write three product description ideas that accurately showcase the features of a set of stainless steel kitchen knives.",
//       "Pretend you own an online pet store. How would you design your website in order to convert first-time visitors into returning customers?",
//       "Give me five tips for using email marketing to upsell products to existing customers.",
//       "Create four customer service scripts that can be used when dealing with complaints about delivery times or refund processing.",
//       "Explain in detail how to use A/B testing to determine the best product photos for an e-commerce store.",
//     ],
//   },
//   {
//     category: "Learn (Skill)",
//     prompts: ["Tell me what else you need to do this"],
//   },
//   {
//     category: "College Apps/Writing",
//     prompts: [
//       "Act as a college interviewer for a Business School. Help me come up with questions I should ask the interviewer at the end of the interview.",
//       "Structure a 1,500-word essay on Max Planck’s quantum theory.",
//       "Suggest some potential topics within the field of [your broader subject] related to [specific interest or theme].",
//       "Act as my brainstorming partner. I’m working on [your broader subject or theme]. What topics could be pertinent for an academic essaay or paper?",
//       "Act as my brainstorming partner for a moment. Given the broader subject of [discipline, e.g., ‘sociology’], can you help generate ideas that intertwine with [specific theme or interest, e.g., ‘social media’] and cater to an audience primarily from [region or demographic, e.g., ‘South East Asia’]?",
//       "How should I structure my essay on [your specific topic]? Provide an outline or potential section headers.",
//       "I’m writing a essay about [your specific topic]. How should I structure it and which sub-topics should I cover within [chosen section, e.g., “Literature Review”]?",
//       "For an essay that discusses [specific topic, e.g., “climate change”], how should I structure the [chosen section, e.g., “Literature Review”] and integrate studies from [specific decade or period, e.g., “the 2010s”]?",
//       "I’m compiling an essay on [specific topic, e.g., “biodiversity loss”]. How should I arrange the [chosen section, e.g., “Discussion”] to incorporate perspectives from [specific discipline, e.g., “socio-economics”] and findings from [specified region or ecosystem, e.g., “tropical rainforests”]?",
//       "Act as an editor for a moment. Based on a paper about [your specific topic], how would you recommend I structure it? Are there key sections or elements I should include?",
//       "Act as a structural consultant for my essay on [topic, e.g., ‘quantum physics’]. Could you suggest a logical flow and potential section headers, especially when I aim to cover aspects like [specific elements, e.g., ‘quantum entanglement and teleportation’]?",
//       "Act as my editorial guide. For an essay focused on [specific topic, e.g., “quantum computing”], how might I structure my [chosen section, e.g., “Findings”]? Especially when integrating viewpoints from [specific discipline, e.g., “software engineering”] and case studies from [specified region, e.g., “East Asia”]?",
//       "Review this passage for grammatical or stylistic errors: [paste your text here].",
//       "Review this paragraph from my [type of document, e.g., “thesis”] for grammatical or stylistic errors: [paste your text here].",
//       "Please review this passage from my [type of document, e.g., “dissertation”] on [specific topic, e.g., “renewable energy”] for potential grammatical or stylistic errors: [paste your text here].",
//       "Kindly scrutinize this segment from my [type of document, e.g., “journal article”] concerning [specific topic, e.g., “deep-sea exploration”]. Highlight any linguistic or structural missteps and suggest how it might better fit the style of [target publication or audience, e.g., “Nature Journal”]: [paste your text here].",
//       "Act as my proofreader. In this passage: [paste your text here], are there any grammatical or stylistic errors I should be aware of?",
//       "Act as my preliminary proofreader. I’ve drafted a section for my [type of document, e.g., “research proposal”] about [specific topic, e.g., “nanotechnology”]. I’d value feedback on grammar, coherence, and alignment with [target publication or style, e.g., “IEEE standards”]: [paste your text here].",
//       "How do I format this citation in [desired style, e.g., APA, MLA]? Here’s the source: [paste source details here].",
//       "I’m referencing a [type of source, e.g., “conference paper”] authored by [author’s name] in my document. How should I format this citation in the [desired style, e.g., “Chicago”] style?",
//       "Act as a citation guide. I need to reference a [source type, e.g., ‘journal article’] for my work. How should I format this using the [citation style, e.g., ‘APA’] method?",
//       "Act as my citation assistant. I’ve sourced a [type of source, e.g., “web article”] from [author’s name] published in [year, e.g., “2018”]. How should I present this in [desired style, e.g., “MLA”] format?",
//       "Can you help me paraphrase this statement? [paste your original statement here].",
//       "Help me convey the following idea from [source author’s name] in my own words: [paste the original statement here].",
//       "I’d like to reference an idea from [source author’s name]’s work on [specific topic, e.g., “quantum physics”]. Can you help me paraphrase this statement without losing its essence: [paste the original statement here]?",
//       "Act as a wordsmith. I’d like a rephrased version of this statement without losing its essence: [paste your original statement here].",
//       "Act as my rephraser. Here’s a statement from [author’s name]’s work on [topic, e.g., ‘cognitive development’]: [paste original statement here]. How can I convey this without plagiarizing?",
//       "Act as my plagiarism prevention aid. I’d like to include insights from [source author’s name]’s research on [specific topic, e.g., “solar energy”]. Help me convey this in my own words while maintaining the tone of my [type of work, e.g., “doctoral thesis”]: [paste the original statement here].",
//       "I want a more academic or sophisticated way to express this: [paste your sentence or phrase here].",
//       "In the context of [specific field or subject], can you suggest a more academic way to express this phrase: [paste your phrase here]?",
//       "I’m writing a paper in the field of [specific discipline, e.g., “bioinformatics”]. How can I convey this idea more academically: [paste your phrase here]?",
//       "Within the purview of [specific discipline, e.g., “astrophysics”], I wish to enhance this assertion: [paste your phrase here]. What terminologies or phrasing would resonate more with an audience well-versed in [related field or topic, e.g., “stellar evolution”]?",
//       "Act as my thesaurus. For this phrase: [paste your sentence or phrase here], is there a more academic or sophisticated term or phrase I could use?",
//       "Act as a lexicon expert in [field, e.g., ‘neuroscience’]. How might I express this idea more aptly: [paste your phrase here]?",
//       "Can you explain the concept of [specific term or concept] in the context of academic research?",
//       "In [specific field, e.g., “sociology”], what does [specific term or concept] mean? And how does it relate to [another term or concept]?",
//       "In the realm of [specific discipline, e.g., “neuroscience”], how would you define [term or concept A], and how does it differentiate from [term or concept B]?",
//       "Act as my tutor. I’m a bit lost on the concept of [specific term or concept]. Can you break it down for me in the context of [specific academic field]?",
//       "Act as my academic tutor for a moment. I’ve encountered some challenging terms in [specific discipline, e.g., “metaphysics”]. Could you elucidate the distinctions between [term A], [term B], and [term C], especially when applied in [specific context or theory, e.g., “Kantian philosophy”]?",
//       "Please provide feedback on this excerpt from my draft: [paste excerpt here].",
//       "Could you review this excerpt from my [type of document, e.g., “research proposal”] and provide feedback on [specific aspect, e.g., “clarity and coherence”]: [paste excerpt here]?",
//       "I’d appreciate feedback on this fragment from my [type of document, e.g., “policy analysis”] that centers on [specific topic, e.g., “renewable energy adoption”]. Specifically, I’m looking for guidance on its [specific aspect, e.g., “argumentative flow”] and how it caters to [intended audience, e.g., “policy-makers in Southeast Asia”]: [paste excerpt here].",
//       "Act as a reviewer for my journal submission. Could you critique this section of my draft: [paste excerpt here]?",
//       "Act as my critique partner. I’ve written a segment for my [type of document, e.g., “literature review”] on [specific topic, e.g., “cognitive biases”]. Could you assess its [specific quality, e.g., “objectivity”], especially considering its importance for [target audience or application, e.g., “clinical psychologists”]: [paste excerpt here].",
//       "Can you recommend key papers or studies related to [your topic or research question]?",
//       "I need references related to [specific topic] within the broader field of [your subject area]. Can you suggest key papers or authors?",
//       "I’m researching [specific topic, e.g., “machine learning in healthcare”]. Can you suggest seminal works from the [specific decade, e.g., “2000s”] within the broader domain of [your general field, e.g., “computer science”]?",
//       "My study orbits around [specific topic, e.g., “augmented reality in education”]. I’m especially keen on understanding its evolution during the [specific time frame, e.g., “late 2010s”]. Can you direct me to foundational papers or figures within [your overarching domain, e.g., “educational technology”]?",
//       "Act as a literature guide. I’m diving into [your topic or research question]. Do you have suggestions for seminal papers or must-read studies?",
//       "Act as my literary guide. My work revolves around [specific topic, e.g., “virtual reality in pedagogy”]. I’d appreciate direction towards key texts or experts from the [specific era, e.g., “early 2000s”], especially those that highlight applications in [specific setting, e.g., “higher education institutions”].",
//       "I’m facing writer’s block on [your topic]. Can you give me some prompts or questions to stimulate my thinking?",
//       "I’m writing about [specific topic] in the context of [broader theme or issue]. Can you give me questions that would enhance my discussion?",
//       "I’m discussing [specific topic, e.g., “urban planning”] in relation to [another topic, e.g., “sustainable development”] in [specific region or country, e.g., “Latin America”]. Can you offer some thought-provoking prompts?",
//       "Act as my muse. I’m struggling with [your topic]. Could you generate some prompts or lead questions to help steer my writing?",
//       "Act as a muse for my writer’s block. Given the themes of [topic A, e.g., ‘climate change’] and its impact on [topic B, e.g., ‘marine ecosystems’], can you generate thought-provoking prompts?",
//       "Help me refine this thesis statement for clarity and impact: [paste your thesis statement here].",
//       "Here’s a draft thesis statement for my paper on [specific topic]: [paste your thesis statement]. How can it be made more compelling?",
//       "I’m drafting a statement for my research on [specific topic, e.g., “cryptocurrency adoption”] in the context of [specific region, e.g., “European markets”]. Here’s my attempt: [paste your thesis statement]. Any suggestions for enhancement?",
//       "Act as my thesis advisor. I’m shaping a statement on [topic, e.g., ‘blockchain in finance’]. Here’s my draft: [paste your thesis statement]. How might it be honed further?",
//       "Can you help me draft/summarize an abstract based on this content? [paste main points or brief content here].",
//       "I’m submitting a paper to [specific conference or journal]. Can you help me summarize my findings from [paste main content or points] into a concise abstract?",
//       "I’m aiming to condense my findings on [specific topic, e.g., “gene therapy”] from [source or dataset, e.g., “recent clinical trials”] into an abstract for [specific event, e.g., “a biotech conference”]. Can you assist?",
//       "Act as an abstracting service. Based on the following content: [paste main points or brief content here], how might you draft or summarize an abstract?",
//       "Act as my editorial assistant. I’ve compiled findings on [topic, e.g., ‘genetic modifications’] from my research. Help me craft or refine a concise abstract suitable for [event or publication, e.g., ‘an international biology conference’].",
//       "I’m using [specific research method, e.g., qualitative interviews] for my study on [your topic]. Can you provide insights or potential pitfalls?",
//       "For a study on [specific topic], I’m considering using [specific research method]. Can you explain its application and potential challenges in this context?",
//       "I’m considering a study on [specific topic, e.g., “consumer behavior”] using [research method, e.g., “ethnographic studies”]. Given the demographic of [target group, e.g., “millennials in urban settings”], what might be the methodological challenges?",
//       "My exploration of [specific topic, e.g., “consumer sentiment”] deploys [research method, e.g., “mixed-method analysis”]. Given my target demographic of [specific group, e.g., “online shoppers aged 18-25”], what are potential methodological challenges and best practices in [specific setting or platform, e.g., “e-commerce platforms”]?",
//       "Act as a methodological counselor. I’m exploring [topic, e.g., ‘consumer behavior patterns’] using [research technique, e.g., ‘qualitative interviews’]. Given the scope of [specific context or dataset, e.g., ‘online retail platforms’], what insights can you offer?",
//       "Please translate this passage to [desired language]: [paste your text here].",
//       "I’m integrating a passage for my research on [specific topic, e.g., “Mesoamerican civilizations”]. Could you assist in translating this content from [source language, e.g., “Nahuatl”] to [target language, e.g., “English”] while preserving academic rigor: [paste your text here]?",
//       "Act as my translation assistant. I have this passage in [source language, e.g., ‘French’] about [topic, e.g., ‘European history’]: [paste your text here]. Can you render it in [target language, e.g., ‘English’] while maintaining academic integrity?",
//       "What are some general ethical considerations when conducting research on [specific topic or population]?",
//       "I’m conducting research involving [specific group or method, e.g., “minors” or “online surveys”]. What are key ethical considerations I should be aware of in the context of [specific discipline or field]?",
//       "My investigation encompasses [specific method or technique, e.g., “genome editing”] on [target population or organism, e.g., “plant species”]. As I operate within the framework of [specific institution or body, e.g., “UNESCO guidelines”], what ethical imperatives should I foreground, especially when considering implications for [broader context, e.g., “global food security”]?",
//       "Act as an ethics board member. I’m conducting research on [specific topic or population]. Could you outline key ethical considerations I should bear in mind?",
//       "Act as an ethics overview guide. My research involves [specific technique or method, e.g., ‘live human trials’] in the realm of [specific discipline, e.g., ‘medical research’]. What general ethical considerations might be paramount, especially when targeting [specific population or group, e.g., ‘adolescents’]?",
//     ],
//   },
//   {
//     category: "Prompt Modifiers",
//     prompts: [
//       "Formal,",
//       "Informal",
//       "Short",
//       "Long",
//       "Generate",
//       "Write XX words",
//       "Act as a <insert job title>",
//       "Make a table of",
//       "How do I create",
//       "List <insert number> ways",
//       "How can you effectively",
//       "Create a bullet list of",
//       "What techniques can I use",
//       "Explain the importance of",
//       "Can you devise",
//       "Suggest tools I can use",
//       "Scan the following text for",
//       "Look for errors in",
//       "How can I optimize",
//       "Can you recommend any",
//       "What would be the most beneficial approach to",
//       "Is there any way to simplify",
//       "How can I make <insert phrase> faster",
//       "Include <insert keyword> in this blog post",
//       "Are there any tips or tricks I can use for",
//       "Write a post specifically for",
//     ],
//   },
// ];
