<template>
  <v-container class="about mt-15">
    <h1>This is an try now page</h1>
  </v-container>
</template>

<script>
export default {
  components: {},
};
</script>
