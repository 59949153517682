<template>
  <div>
    <nav-component />
    <v-container>
      <div v-if="loading.value" class="loader" />
      <div>
        <p class="font-weight-bold text-center section-title">
          <b>Contact Us</b>
        </p>
        <p class="mt-10">
          Got an idea for a Channel? Found a bug? Any other ideas or
          suggestions?
        </p>
        <p class="mb-10">Drop us a line here:</p>
      </div>
      <form class="py-10" @submit.prevent="submitForm">
        <v-row class="justify-center">
          <v-col cols="12" xs="3" sm="4">
            <v-text-field
              v-model="state.name"
              :error-messages="v$.name.$errors.map((e) => e.$message)"
              label="Name*"
              required
              @input="v$.name.$touch"
              @blur="v$.name.$touch"
            />
          </v-col>
          <v-col cols="12" xs="3" sm="4">
            <v-text-field
              v-model="state.email"
              :error-messages="v$.email.$errors.map((e) => e.$message)"
              label="E-mail*"
              required
              @input="v$.email.$touch"
              @blur="v$.email.$touch"
            />
          </v-col>
        </v-row>
        <v-row class="justify-center mb-3">
          <v-col cols="12" xs="6" sm="8">
            <v-textarea
              v-model="state.message"
              required
              :error-messages="v$.message.$errors.map((e) => e.$message)"
              label="Message*"
            />
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-btn
            class="me-4 bg-black px-10"
            type="submit"
            :disabled="loading.value"
            @click="v$.$validate"
          >
            <span v-if="loading.value">Loading...</span>
            <span v-else>Send</span>
          </v-btn>

          <v-btn class="me-4 px-10" @click="clear"> Clear </v-btn>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import ApiService from "@/services/ApiService";
import Swal from "sweetalert2";
import NavComponent from "@/components/NavComponent.vue";

export default {
  components: {
    NavComponent,
  },
  setup() {
    const initialState = {
      name: "",
      email: "",
      message: "",
    };

    const loading = reactive({ value: false });

    const state = reactive({
      ...initialState,
    });

    const rules = {
      name: { required },
      email: { required, email },
      message: { required },
    };

    const v$ = useVuelidate(rules, state);

    function clear() {
      v$.value.$reset();

      for (const [key, value] of Object.entries(initialState)) {
        state[key] = value;
      }
    }

    function submitForm() {
      loading.value = true;
      ApiService.createContact(state)
        .then((response) => {
          if (response.data.response_status == "200") {
            Swal.fire({
              title: "Thank you for submitting the request",
              confirmButtonColor: "#2f363d",
              confirmButtonText: "<span style='margin:30px'>OK</span>",
            });
            clear();
          } else {
            Swal.fire({
              title:
                "Error submitting data, Make sure to fill in correct values",
              confirmButtonColor: "#2f363d",
              confirmButtonText: "<span style='margin:30px'>OK</span>",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Something went wrong please try again later",
            confirmButtonColor: "#2f363d",
            confirmButtonText: "<span style='margin:30px'>OK</span>",
          });
          console.error("Error submitting data:", error);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    return { state, clear, v$, loading, submitForm };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 500px) {
  form {
    margin: 1em;
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}
</style>

<codepen-resources lang="json">
{
  "js": [
    "https://cdn.jsdelivr.net/npm/@vuelidate/core/dist/index.js",
    "https://cdn.jsdelivr.net/npm/@vuelidate/validators/dist/index.js"
  ],
  "imports": {
    "vue-demi": "https://cdn.jsdelivr.net/npm/vue-demi/lib/index.mjs",
    "@vuelidate/core": "https://cdn.jsdelivr.net/npm/@vuelidate/core/dist/index.esm.js",
    "@vuelidate/validators": "https://cdn.jsdelivr.net/npm/@vuelidate/validators/dist/index.esm.js"
  }
}
</codepen-resources>
