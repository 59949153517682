export const API_BASE_URL = process.env.VUE_APP_BASE_URL + "/api";
export const DOMAIN_ENDPOINT = API_BASE_URL + "/domain/";
export const REDIRECT_URL = process.env.VUE_APP_BASE_URL + "/app";

export const frequentlyAskedQuestions = [
  {
    question: "Focus on the most important:",
    answer: [
      "Prompt: Act as an expert in [Topic]. My goal is to learn [Skill] as quickly as possible. Make a list of the most important things I need to know that will allow me to master this topic.",
      "Prompt: Act as if you are my travel guide: Create a 7 day itinerary to see the best sights in Spain and Portugal",
    ],
  },
  {
    question: "Define and Understand a Concept",
    answer: [
      "Prompt: Define [Term] and provide an example that can be used in everyday life. The definition should be complete, but easy to understand, explaining any complicated words if necessary",
    ],
  },
  {
    question: "Relate Multiple topics",
    answer: [
      "Prompt: Describe in simple words the relationship between [Concept 1] and [Concept 2]",
    ],
  },
  {
    question: "Consult and expert and ask Questions",
    answer: [
      "Prompt: I want you to act as an expert in [Topic] and give me recommendations for [Specific Questions]",
    ],
  },
  {
    question: "Learn the most Important Terms",
    answer: [
      "Prompt: What are some key terms I should know about [Topic]? Make a list with a short simple definition of each term.",
    ],
  },
  {
    question: "Create a Learning Plan",
    answer: [
      "Prompt: I want to learn [Topic]. Give me step by step instructions on how to learn [Skill]. Start with the basic and move on to the more difficult ones.  Keep in mind that I’m a beginner",
    ],
  },
  {
    question: "Easily Understand Anything",
    answer: [
      "Prompt: Can you explain the concept of [Topic] in simple terms? Summarize the main principles and illustrate with examples to make it easier to understand.",
    ],
  },
  {
    question: "Increase your Productivity",
    answer: [
      "Prompt:  Enhance team productivity in [Business] by implementing the SCRUM methodology",
      "Prompt: A customer made a purchase. Write a follow up email thanking them for their purchase and asking them to leave a review or feedback (Save time writing follow up emails!). Make it friendly, polite, and short.",
      "Prompt: Analyze [business] and ]business model]. Consider the market space and find the faults that could make businesses fail or slow down. Update the [business model] to solve all the faults you find",
      "Prompt: Write a product description for a [product] for the [target audience]. Use a witty, funny tone because that is what our brand stands for. (Product Descriptions)",
      "Prompt: Build a conversation between two people; John and Robert. John is a business owner and Robert is a marketing genius. Now show exactly how this sales conversation goes from start to end. (Simulate a Sales Conversation)",
    ],
  },
  {
    question: "Develop a Powerful Brand",
    answer: [
      "Prompt: Utilize the Golden Circle Model to create a powerful brand for [Product or Service]",
    ],
  },
  {
    question: "Prepare a Crisis Plan",
    answer: [
      "Prompt: Develop a crisis management plan to [Business] to effectively respond to and mitigate potential crises, safeguard brand reputation, and ensure business continuity",
    ],
  },
  {
    question: "AI Speech Writing",
    answer: [
      "Prompt: You are Speech GPT- Your primary function is to write a speech based on the information given below.",
    ],
    subPoints: [
      "Who wrote the speech [CEO of a big firm]",
      "Who’s the target audience [My employees]",
      "What is the goal of the speech [Inspire people] ",
    ],
  },
];
