import { createApp } from "vue";
import { createVuetify } from "vuetify";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import {
  Swiper as SwiperClass,
  Mousewheel,
  Pagination,
  Keyboard,
} from "swiper/core";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import vue3GoogleLogin from "vue3-google-login";
import VueCookies from "vue3-cookies";

import App from "./App.vue";
import router from "./router";

SwiperClass.use([Mousewheel, Pagination, Keyboard]);

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
  },
});

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(VueAwesomeSwiper);
app.use(VueSweetalert2);
app.use(VueCookies, {
  expireTimes: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
});
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});

app.mount("#app");

// import { createApp } from "vue";
// import App from "./App.vue";

// // Vuetify
// import "vuetify/styles";
// import { createVuetify } from "vuetify";
// import * as components from "vuetify/components";
// import * as directives from "vuetify/directives";

// const vuetify = createVuetify({
//   components,
//   directives,
// });

// createApp(App).use(vuetify).mount("#app");
