<template>
  <div>
    <NavComponent @div-clicked="scrollToSection" />
    <v-container class="home-banner d-flex align-center height">
      <v-row align="center" no-gutters>
        <v-col
          cols="12"
          class="d-flex justify-center align-center text-center text-md-left"
        >
          <div class="w-100 text-center lg:ban0er-text-container px-3 px-lg-0">
            <p
              class="custom-margin text-center lg:fot0-weight-medium text-gray welcome-text"
            >
              Welcome to
            </p>
            <p class="text-center lg:tet0-black text-title">TuringWeb</p>

            <br />
            <p class="text-sub-title text-center lg:font-weight-black">
              The future of Artificial Intelligence is
              <em class="underlined">human!</em>
            </p>

            <div class="mt-6 text-center mx-auto">
              <ask-question />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- <div id="channel-section" class="section-2 d-none">
      <v-container class="py-0" style="padding-bottom: 100px" align="center">
        <v-row style="padding-bottom: 50px; padding-top: 50px">
          <RotatingDivs />
        </v-row>
      </v-container>
    </div> -->

    <!-- <div class="section-padding pb-0 d-none">
      <v-container>
        <p class="font-weight-bold section-title">
          Where curiosity finds answers
        </p>
        <v-row class="section-padding justify-between">
          <v-col
            v-for="item in sectionTwo"
            :key="item.id"
            class="d-flex justify-center"
            cols="12"
            sm="4"
            md="4"
            lg="4"
          >

            <CustomCard
              class="mx-auto"
              :title="item.title"
              :text="item.text"
              :source="item.source"
              :border-color="item.borderColor"
            />
          </v-col>
        </v-row>
      </v-container>
    </div> -->
    <FooterComponent />
  </div>
</template>

<script>
// @ is an alias to /src
// import CustomCard from "@/components/customCard.vue";
import NavComponent from "@/components/NavComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
// import RotatingDivs from "@/components/RotatingDivs.vue";
import AskQuestion from "@/components/Home/AskQuestion.vue";
export default {
  name: "HomeView",
  components: {
    // CustomCard,
    // CustomCardImage,
    // Channel,
    NavComponent,
    FooterComponent,
    // RotatingDivs,
    AskQuestion,
  },
  data: function () {
    return {
      sectionTwo: [
        {
          id: 1,
          title: "Coexist with Communities",
          source: require("../assets/images/communities.png"),
          borderColor: "rgba(109, 175, 184, 0.45)",
          text: "Explore a wide range of domains including Competitive Tests, Health and Fitness, Acute Diseases, and more. Connect with like-minded individuals facing similar challenges and discover valuable insights together",
        },
        {
          id: 2,
          title: "Frequently Asked Questions",
          source: require("../assets/images/faq.png"),
          borderColor: "rgba(225, 220, 93, 0.45)",
          text: "Quickly find answers to common queries in each domain. Our curated collection of frequently asked questions will help you get the information you need without any hassle",
        },
        {
          id: 3,
          title: "Chat with ChatGPT",
          source: require("../assets/images/gpt.png"),
          borderColor: "rgba(14, 169, 130, 0.45)",
          text: "Need clarity on a specific topic or discussion? Our intelligent chatbot assistant, ChatGPT, is here to help. Engage in interactive conversations and receive instant guidance from an AI-powered expert.",
        },
      ],
    };
  },
  methods: {
    scroll(sectionName) {
      const channel_section = document.getElementById(sectionName);
      if (channel_section) {
        channel_section.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToSection(section_name) {
      switch (section_name) {
        case "channel":
          this.scroll("channel-section");
          break;
        case "FAQs":
          this.scroll("FAQs-section");
          break;
        case "contact":
          this.scroll("contact-section");
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.height {
  height: 90vh;
}

.underlined {
  position: relative;
}

.underlined:after {
  content: "";
  position: absolute;
  bottom: -0.7rem;
  left: -0.5rem;
  right: -0.5rem;
  height: 1rem;

  z-index: -1;

  background-image: url("../assets/images/clip_path.svg");
  background-repeat: no-repeat;

  background-size: cover;
}

@media only screen and (max-width: 550px) {
  .underlined:after {
    height: 0.8rem;
  }
}
</style>
